import React, { useEffect, useState } from "react"
import BgImg from "gatsby-background-image"
import * as styles from "./simple-hero.module.scss"

const SimpleHero = ({ banner, tag, category }) => {
  const { background_image, heading, subheading } = banner.elements

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const bgOverlay = "PrimaryColor"

  if (background_image?.value.length) {
    return (
      <div className={styles.bannerContainer}>
        <div
          className={`${styles.bannerContent}`}
          style={{ transform: `translateY(-${offset * 0.25}px)` }}
        >
          <span className="container">
            {tag || category ? (
              <h1>{tag ? tag : `Articles tagged with ${category}`}</h1>
            ) : (
              <>
                <h1>{heading.value}</h1>

                {subheading?.value.length > 0 && (
                  <h4 className={styles.simpleHeroSubheading}>
                    {subheading.value}
                  </h4>
                )}
              </>
            )}
          </span>
        </div>
        <div
          className={`${bgOverlay}Bg ${styles.overlay}`}
          id="simple-hero-override"
        ></div>
        <BgImg
          fluid={background_image.value[0].fluid}
          alt={background_image.value[0].description}
          className={`${styles.simpleHero}`}
          style={{ transform: `translateY(-${offset * 0.45}px)` }}
        />
      </div>
    )
  } else {
    return (
      <div
        className={`${styles.simpleHeroTextOnly} ${bgOverlay}Bg`}
        id="simple-hero-override"
      >
        <div className={styles.bannerContent}>
          <span className="container">
            {tag || category ? (
              <h1>{tag ? tag : category}</h1>
            ) : (
              <h1>{heading.value}</h1>
            )}
          </span>
        </div>
      </div>
    )
  }
}

export default SimpleHero
